
















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import slugify from 'slugify';
import { ApiResponse } from '@/components/types';
import { CashAccountDetails } from '@/modules/listOfAssets/types';
import { FETCH_CASH_ACCOUNT, GET_CASH_ACCOUNT } from '@/modules/listOfAssets/store/cashAccount';
import CardRow from '@/components/CardRow.vue';
import FinanceAccountOpenedDialog from '../components/details/dialogs/FinanceAccountOpenedDialog.vue';
import FinanceAccountStartDepositDialog from '../components/details/dialogs/FinanceAccountStartDepositDialog.vue';
import FinanceAccountCloseDialog from '@/modules/listOfAssets/components/details/dialogs/FinanceAccountCloseDialog.vue';
import TransactionExportDialog from '@/modules/listOfAssets/components/TransactionExportDialog.vue';
import TransactionCard from '@/modules/listOfAssets/components/details/cards/TransactionCard.vue';
import CashAccountDialog from '../components/details/dialogs/CashAccountDialog.vue';
import VoucherExportDialog from '@/modules/listOfAssets/components/VoucherExportDialog.vue';

const CashAccount = namespace('listOfAssets/cashAccount');

@Component({
  components: {
    VoucherExportDialog,
    CashAccountDialog,
    TransactionCard,
    TransactionExportDialog,
    FinanceAccountCloseDialog,
    FinanceAccountStartDepositDialog,
    FinanceAccountOpenedDialog,
    CardRow
  }
})
export default class CashAccountDetailsView extends Vue {
  @CashAccount.Action(FETCH_CASH_ACCOUNT) fetchDetails!: (account: string) => Promise<ApiResponse>;
  @CashAccount.Getter(GET_CASH_ACCOUNT) getDetails!: (account: string) => CashAccountDetails | undefined;

  get account(): CashAccountDetails | undefined {
    return this.getDetails(this.$route.params['id']);
  }

  get backURL() {
    if (this.$route.query['referrer']) {
      return this.$route.query['referrer'];
    }

    if (!this.account) return '';

    return { name: 'list-of-assets-details', params: { id: this.account.listOfAssetsId } };
  }

  slugify(string: string | null) {
    if (!string) {
      string = 'Bargeldkonto';
    }

    return slugify(string);
  }

  @Watch('$route.params.id', { immediate: true })
  onIdChange() {
    return this.fetchDetails(this.$route.params['id']);
  }
}
